<template>
<v-app>
    <SingleAppBar title="ร้านค้า" :backward="true">
        <template v-slot:extension>
        <v-tabs
          v-model="tab"
          background-color="white"
          fixed-tabs
          icons-and-text
          height="90"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items"  :key="item.title">
           <v-img contain width="100" :src="require(`@/assets/${item.filename}`)"/>
        </v-tab>
          <!-- <v-tab
            :key="'ชีวิตดี'"
          >
          <v-img contain width="100" :src="require(`@/assets/LogoChividD.jpeg`)"/>
          </v-tab>
           <v-tab
            :key="'มูลนิธิรามา'"
          >
          <v-img contain width="100" :src="require(`@/assets/RamaFound.png`)"/>
          </v-tab>
           <v-tab
            :key="'เอสซีจีเอ็กเพลส'"
          >
          <v-img contain width="100" :src="require(`@/assets/ExpressLoo.png`)"/>
          </v-tab> -->
        </v-tabs>
      </template>
    </SingleAppBar>
    <v-main class="natural lighten-4 fill-height">
      <v-container fluid class="pt-15 mt-5">
      <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.title"
      >
      <v-row>
          <v-col cols="12" v-for="(subitem, index) in item.items" :key="index">
          <v-card @click="redirect(subitem)">
         <v-img :src="subitem.src"/>
        </v-card>
          </v-col>
      </v-row>
      </v-tab-item>
    </v-tabs-items>
      </v-container>
     <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
</v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";
import {getBanner} from '@/api/'

export default {
  name: "Shopping",
  components: {
    SingleAppBar
  },
  methods: {
      redirect(item, target = '_blank') {
          this.$gtag.event('Banner', {
        event_category: 'Shopping',
        event_label: item.src,
        value: item.url
      })
            window.open(item.url, target);
        },
      async fetchData () {
         try {
           this.loading = true
           let banner = await getBanner()
           if(banner.data.code ===1) {
             this.items[0].items = banner.data.result
           }
         } catch(error) {
           console.log(error)
         } finally {
           this.loading = false
         }
       }
  },
  data() {
    return {
        tab: null,
        loading: false,
        items: [
          {title: 'ชีวิตดี' ,filename: 'LogoChividD.jpeg',items: [
          {
            src: 'https://mapsite.blob.core.windows.net/loginui/Chivit-DxVwell_Banner_1.jpg',
            url: 'https://bit.ly/3wT2LqM'
          },
          {
            src: 'https://mapsite.blob.core.windows.net/loginui/Chivit-DxVwell_Banner_2.jpg',
            url: 'https://bit.ly/34L5uXh'
          },
          {
            src: 'https://mapsite.blob.core.windows.net/loginui/Chivit-DxVwell_Banner_3.jpg',
            url: 'https://bit.ly/3vPFYfp'
          },
          {
            src: 'https://mapsite.blob.core.windows.net/loginui/Chivit-DxVwell_Banner_4.jpg',
            url: 'https://bit.ly/3fQmjXi'
          },
        ],},
            // {title: 'มูลนิธิรามา' ,filename: 'RamaFound.png',content: 'hello',items: []},
            // {title: 'เอสซีจีเอ็กเพลส' ,filename: 'ExpressLoo.png',content: 'hello',items: []},
        ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
   created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>
