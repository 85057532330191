<template>
  <v-card>
      <v-card-text class="text-center mx-auto">
          <div class="pa-6"><v-avatar size="56">
              <v-img :src="require('@/assets/erroricon.svg')"></v-img>
              </v-avatar> </div>
          <div class="primary--text text--darken-4 text-h5">รหัสบริการไม่ถูกต้อง</div>
          <div class="natural--text text--darken-2 text-title-3">คุณสามารถทำการสแกนคิวอาร์โค้ด
หรือกรอกข้อมูลใหม่อีกครั้ง</div>
       
      </v-card-text>
      <v-card-actions>
   <v-row>
    <v-col cols="12" class="pa-2"><v-btn block color="primary" large @click="$emit('onClose')"><span class="text-title-2">สแกนใหม่อีกครั้ง</span></v-btn></v-col>
    <v-col cols="12" class="pa-2" v-if="$auth.role.service.length > 0"><v-btn  block outlined color="primary" large @click="$emit('onSkip')"><span class="text-title-2">กลับหน้าแรก</span></v-btn></v-col>
             
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>