<template>
    <v-app-bar absolute  color="primary" elevation="0">
    <v-app-bar-nav-icon @click="$router.push({name: 'main'})" data-cy="CameraNavBar-backward-btn">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
     
         </v-app-bar-nav-icon>
         <v-spacer/>
          <v-toolbar-title>
   <div class="text-title-1 natural--text">{{title}}</div>
    </v-toolbar-title>
  
     <!-- <v-app-bar-title class="text-title-1 white--text">{{title}}</v-app-bar-title> -->
        <v-spacer/>
<v-btn icon></v-btn>
 <!-- <template v-slot:extension>
 <v-text-field solo dense  placeholder="รหัสบริการ" class="text-subtitle-1"></v-text-field>
        </template> -->
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward:{
      type: Boolean,
      default: false
    }
  },

}
</script>

<style>

</style>