<template>
  <v-app>
    <CameraNavBar :title="'สแกนคิวอาร์โค้ด'" :backward="true"/>
    <v-main>
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" >
         <canvas ref="cv" class="canvas"></canvas>
        <v-btn  icon class="switchbutton" @click="switchCamera">
        <v-icon size="30">mdi-camera-party-mode</v-icon>
      </v-btn>  
            <v-btn icon class="flashbutton" @click="torchActive = !torchActive" :disabled="torchNotSupported">
   <v-icon size="30">mdi-flash</v-icon>
      </v-btn>

      </qrcode-stream> 
      <CameraLoadingCard :overlay="loading" />
      <v-dialog v-model="dialog" persistent>
        <CameraFailCard @onClose="onDialogClose()" @onSkip="$router.push({ name: 'main'})"/>
      </v-dialog>
    </v-main>
    <SiteContentCard :service="service" :content="content"/>
      <v-footer
    padless
    fixed
    >
    <v-card
        flat
        tile
        width="100%">
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="result" outlined placeholder="รหัสบริการ เช่น VWELL01234" class="text-subtitle-1" :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"></v-text-field>
          <v-btn block large color="primary text-title-2" @click="onSubmit(result)">เพิ่มบริการ</v-btn>
           </v-form>
           <!-- <v-btn  block x-large color="primary" @click="$router.push({name: 'kyc'})">เพิ่มบริการ</v-btn> -->
        </v-card-text>
        </v-card>
      </v-footer>
  </v-app>
</template>

<script>
import CameraNavBar from "@/components/qrscan/QRNavBar";
import CameraLoadingCard from "@/components/camera/CameraLoadingCard";
import CameraFailCard from '@/components/camera/CameraFailCard'
import SiteContentCard from '@/components/sitecontent/SiteContentCard'
import { ocrIdcard, postService, getSiteContent, user, serviceAuthen}  from "@/api"
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapFields } from "vuex-map-fields";
import { mask } from "vue-the-mask";
// eslint-disable-next-line no-unused-vars

export default {
  components: {
    CameraNavBar,
    CameraLoadingCard,
    CameraFailCard,
    QrcodeStream,
    SiteContentCard
  },
  directives: { mask },
  data() {
    return {
      valid: false,
      overlay: true,
      service: {},
      mediaStream: null,
      snackmessage: "",
      snackbar: false,
      isChrome57or58: null,
      loading: false,
      dialog: false,
      result: null,
       options: [
      { text: "outline", value: this.paintOutline },
      { text: "centered text", value: this.paintCenterText },
      { text: "bounding box", value: this.paintBoundingBox },
    ],
    selected: { text: "bounding box", value: this.paintBoundingBox },
    camera: 'auto',
    noRearCamera: false,
    noFrontCamera: false,
    torchActive: false,
    torchNotSupported: false,
    isValid: undefined,
    content: []
    };
  },
  computed: {
      ...mapFields(["kyc"]),
    validationPending () {
      return this.isValid === undefined
        && this.camera === 'off'
    },
    validationSuccess () {
      return this.isValid === true
    },
    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
    onDialogClose () {
      this.turnCameraOn()
      this.dialog=false
    },
    switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
        case 'auto':
          this.camera = 'front'
          break
      }
    },
    async onInit (promise) {
        try {
        const { capabilities } = await promise

        console.log(capabilities);

        this.torchNotSupported = !capabilities.torch

         this.$refs.cv.width = screen.width;
            this.$refs.cv.height = screen.height;
            let ctx = this.$refs.cv.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0.5)";
            ctx.fillRect(0, 0, screen.width, screen.height / 5);
            ctx.fillRect(
              0,
              (screen.height / 3) * 2,
              screen.width,
              screen.height
            );

            ctx.beginPath();
            ctx.lineWidth = "1";
            ctx.strokeStyle = "red";
            ctx.moveTo(screen.width/2, 0);
            ctx.lineTo(screen.width/2, screen.height);
            ctx.stroke();
            // ctx.fillStyle = "rgba(0,0,0,0.5)";
            // ctx.fillRect(0, 0, screen.width, screen.height / 5);
            // ctx.fillRect(
            //   0,
            //   (screen.height / 3) * 2,
            //   screen.width,
            //   screen.height
            // );
          
          

      } catch (error) {
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
        }

        console.error(error)
      }
    },
    addService (code) {
        this.loading = true
      serviceAuthen({inviteCode: code},message=>{
          if (message.data.code === 1) {
          const tenantId = message.data.result.tenantId
          const siteId = message.data.result.siteId
          this.service = message.data.result
            getSiteContent({ tenantId: tenantId, siteId: siteId }, message => {
                 if (Array.isArray(message.data.result) && message.data.result.length > 0) {
                   message.data.result.forEach(function (element) { 
                     element.dialog = true
                     element.process = false
                     element.isAccept = null
                     element.tenantId = tenantId
                     element.siteId = siteId
                     element.mode = 'new'
                    })
                   this.content = message.data.result
                 } else {
                    this.$router.push({ name: 'main' })
                 }
              }, error => {
                console.log(error)
              })

          }
         this.loading = false
      },error=>{
          console.log(error)
          this.dialog = true
          this.loading = false
      })
    },
    postService (code) {
      this.loading = true
      postService({
        cid: this.$auth.role.cid,
        inviteCode: code
      }, message => {
        if (message.data.code === 1) {
          this.service = message.data.result
          const tenantId = message.data.result.tenantId
          const siteId = message.data.result.siteId
          const serviceType = message.data.result.serviceType
          if (serviceType === 'cms') {
              console.log('service type CMS')
              this.kyc = message.data.result
          }
          user(message => {
            if (message.data.code === 1) {
              // update role
              this.$auth.role = message.data.result
              getSiteContent({ tenantId: tenantId, siteId: siteId }, message => {
                 if (Array.isArray(message.data.result) && message.data.result.length > 0) {
                   message.data.result.forEach(function (element) { 
                     element.dialog = true
                     element.process = false
                     element.isAccept = null
                     element.tenantId = tenantId
                     element.siteId = siteId
                     element.mode = 'new'
                    })
                   this.content = message.data.result
                 } else {
                    if (serviceType === 'cms') {
                        this.$router.replace({ name: 'kyc' })
                    }else{
                        this.$router.replace({ name: 'main' })
                    }
                   // this.$router.push({ name: 'main' })
                 }
              }, error => {
                console.log(error)
              })
            }
          })
        } else {
          this.dialog = true
          this.result = null
        }
        this.loading = false
      }, error => {
          console.log(error)
          this.dialog = true
          this.result = null
          this.loading = false
      })
    },
    onSubmit (result) {
      if (this.$refs.form.validate()) {
       this.addService(result)
      }
    },
    onDecode (result) {
      this.turnCameraOff()
      this.addService(result)
      //this.postService(result)
      //this.$router.push({ name: 'home'})
    },
      paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
        this.onDecode(detectedCode.rawValue)
        // console.log(detectedCode.rawValue)
      }
    },

    paintCenterText (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode

        const centerX = boundingBox.x + boundingBox.width/ 2
        const centerY = boundingBox.y + boundingBox.height/ 2

        const fontSize = Math.max(12, 50 * boundingBox.width/ctx.canvas.width)
        console.log(boundingBox.width, ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = "center"

        ctx.lineWidth = 3
        ctx.strokeStyle = '#35495e'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#5cb984'
        ctx.fillText(rawValue, centerX, centerY)
      }
    },

    logErrors (promise) {
      promise.catch(console.error)
    },
     turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },
   processImg() {
      this.loading = true

       const canvas = document.createElement('canvas')
               canvas.width = this.$refs.video.videoWidth
               canvas.height = this.$refs.video.videoHeight
               //Choice full canvas
               canvas.getContext('2d').drawImage(this.$refs.video, 0, 0 , canvas.width, canvas.height)

               //Choice 1 Fit screen crop 
               //canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2-20),(canvas.height-screen.height)/2,screen.width,screen.height,0,0,screen.width,screen.height)

               //Choice 2 Center Crop
               //canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2-20),((canvas.height-screen.height)/2)+(screen.height/4)+10,screen.width,screen.height/3,0,0,screen.width,(screen.height/3)) 

               var dataURL = canvas.toDataURL()
               //dataURL.replace('data:image/png;base64,','')
               let base64ContentArray = dataURL.split(",")   
               let base64Data = base64ContentArray[1]
               console.log(base64Data)
      let data= {
        image: base64Data,
        side: "front"
      }
      ocrIdcard(data,message=>{
        console.log(message)
        if(message.data.code===1){
        this.citizenObj = message.data.result.result
        this.$router.push({ name: 'info'})
        }else{
        this.dialog = true
        }
        this.loading = false
      },error=>{
        console.log(error)
        this.loading = false
      })

    }
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
border-radius: 6px;
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  max-height: 100%;
}
#instruction {
  position: absolute;
  margin: auto;
  width:100%;
  top: 70px;
  z-index: 0;
}
#instruction2{
  position: absolute;
  margin: auto;
  width: 100%;
  bottom: 50px;
  z-index: 0;
}
.take-picture-button {
  display: block;
  margin: auto;
  bottom: 80px;
}
.switchbutton {
  position: absolute;
  right: 10px;
  top: 70px;
}
.flashbutton {
  position: absolute;
  right: 50px;
  top: 70px;
}
#videoContainer {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    z-index: 0;
  }
}
</style>