<template >
    <div>
    <v-dialog v-model="item.dialog" v-for="(item, index) in content" :key="index" scrollable fullscreen>
    <v-card flat tile>
       <v-card-title><v-spacer/><v-btn v-if="item.mode === 'view'" icon @click="item.dialog= false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text class="pt-10">
            <!-- <p v-html="item.consentType"/> -->
            <p v-html="item.contentTh"/>
        </v-card-text>
         <v-card-actions>
               <v-radio-group
      v-model="item.isAccept"
      row
      class="mx-auto"
    >
    <v-spacer/>
      <v-radio
        label="ยินยอม"
        :value= true
      ></v-radio>
       <v-spacer/>
       <v-spacer/>
      <v-radio
        label="ไม่ยินยอม"
        :value= false
      ></v-radio>
       <v-spacer/>
    </v-radio-group>
         </v-card-actions>
        <v-card-actions><v-btn x-large color="primary" block @click="postSiteConsent(item)">ตกลง</v-btn></v-card-actions>
    </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { postSiteConsent } from "@/api"

export default {
  props: {
    content: {
      type: Array
    },
    service: {
      type: Object
    }
  },
  methods: {
      postSiteConsent (item) {
          item.process = true
          postSiteConsent(item, async (message) =>{
              if(message.data.code === 1) {
                if(item.isAccept === false) {
                  // if(item.contentType === 'term_condition') this.$router.replace({ name: 'main' })
                }
              }
              item.dialog = false
          }, error => {
              item.dialog = false
              console.log(error)
          })
          if(item.mode === 'new') {
          const process = this.content.filter(value=> value.process === false)
          if (process.length === 0) {
              if (this.service.serviceType === 'cms') {
                  this.$router.replace({ name: 'main' })
              } else {
                 this.$router.replace({ name: 'main' })
              }
             
          }

          }
        
      }
  },
}
</script>

<style lang="scss" scoped>
.v-btn {
border-radius: 6px;
}
</style>
